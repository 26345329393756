import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Header from "./components/Header";
import Survey from "./survey/survey";
import Home from "./components/home";
import "./App.css";
import DemoB2C from "./demo/demoB2C/demoB2C";
import DemoB2B from "./demo/demoB2B/demoB2B";
import EmbedDesigner from "./components/embedDesigner/EmbedDesigner";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './components/Footer';
import ContactPage from './components/contact/Contact';
import ChatWidgetComponent from "./components/widget/ChatWidget"; // Import the ChatWidgetComponent
import { trackPageView } from './analytics';
import { analytics } from './firebaseConfig'; // Add this import
import Events from './components/Events';
import FullScreenWidget from './components/FullScreenWidget';
import LiveDemos from "./components/LiveDemos";
import DemoB2CPage from './pages/DemoB2CPage';
import DemoB2CAllPage from './pages/DemoB2CAllPage';
import DemoB2BPage from './pages/DemoB2BPage';

function AppContent() {
  const [widgetProps, setWidgetProps] = useState({
    primaryColor: '#000000',
    secondaryColor: 'darkgrey',
    email: '',
    isOnWebsite: false,
    outfitsNum: 2,
    searchTop: 30,
    storeId: 'revolve',
    buttonText: 'Style Assistant'
  });

  const location = useLocation();

  useEffect(() => {
    if (analytics) {
      trackPageView('home');
    } else {
      console.warn('Analytics not initialized');
    }
  }, []);

  const isSearchRoute = location.pathname.startsWith('/search');

  return (
    <div className="App">
      {!isSearchRoute && <Header />}
      <div className="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/community" element={<Events />} />
          <Route path="/survey" element={<Survey />} />
          <Route path="/about" element={<About />} />
          <Route path="/demo" element={<LiveDemos />} />
          <Route path="/demo/b2c/:store" element={<DemoB2CPage />} />
          <Route path="/demo/b2c/all" element={<DemoB2CAllPage />} />
          <Route path="/demo/b2b" element={<DemoB2BPage />} />
          <Route path="/widget" element={<EmbedDesigner setWidgetProps={setWidgetProps} />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/search/:storeId?" element={<FullScreenWidget />} />
        </Routes>
      </div>
      {!isSearchRoute && <Footer />}
      {!isSearchRoute && location.pathname == '/demo' && (
        <div id="global-chat-widget-container">
          <ChatWidgetComponent {...widgetProps} />
        </div>
      )}
      <ToastContainer />
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function About() {
  return (
    <main>
      <h2>About Styld</h2>
      <p>We help you find your perfect style.</p>
    </main>
  );
}

export default App;
