import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { auth } from "../../firebaseConfig";
import {
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

function InWidgetAuthModal({ show, handleClose, onAuthSuccess }) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [isCodeSent, setIsCodeSent] = useState(false);

  const setupRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        'size': 'invisible',
        'callback': () => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        }
      });
    }
  };

  const handleSendCode = async () => {
    if (!phoneNumber || !isValidPhoneNumber(phoneNumber)) {
      alert("Please enter a valid phone number");
      return;
    }

    try {
      setupRecaptcha();
      const confirmation = await signInWithPhoneNumber(auth, phoneNumber, window.recaptchaVerifier);
      setConfirmationResult(confirmation);
      setIsCodeSent(true);
    } catch (error) {
      alert("Error sending verification code: " + error.message);
    }
  };

  const handleVerifyCode = async () => {
    if (!verificationCode) {
      alert("Please enter the verification code");
      return;
    }

    try {
      const result = await confirmationResult.confirm(verificationCode);
      onAuthSuccess(result.user);
      handleClose();
    } catch (error) {
      alert("Error verifying code: " + error.message);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} dialogClassName="custom-modal">
      <Modal.Header closeButton>
        <Modal.Title>Phone Authentication</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formPhoneNumber">
            <Form.Label>Phone Number:</Form.Label>
            <PhoneInput
              placeholder="Enter phone number"
              value={phoneNumber}
              onChange={setPhoneNumber}
              defaultCountry="US"
              className="PhoneInput"
              inputClass="PhoneInputInput"
              international
              countryCallingCodeEditable={false}
            />
          </Form.Group>

          {isCodeSent && (
            <Form.Group controlId="formVerificationCode">
              <Form.Label>Verification Code:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter verification code"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                className="custom-input"
              />
            </Form.Group>
          )}
        </Form>
        <Button
          variant="primary"
          className="w-100 mt-2 custom-button"
          onClick={isCodeSent ? handleVerifyCode : handleSendCode}
        >
          {isCodeSent ? "Verify Code" : "Send Verification Code"}
        </Button>
      </Modal.Body>
      <div id="recaptcha-container"></div>
    </Modal>
  );
}

export default InWidgetAuthModal;
