import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import LogoImage from "./logo.png";
import styles from "./widget.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import InWidgetAuthModal from './InWidgetAuthModal';
import Survey from "../../survey/survey";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebaseConfig";
import { Cart, Send } from 'react-bootstrap-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const ChatWidgetComponent = ({
  primaryColor = "#007bff",
  secondaryColor = "#0056b3",
  backgroundColor = "#ffffff",
  textColor = "#333333",
  buttonTextColor = "#ffffff",
  fontFamily = "Arial, sans-serif",
  borderRadius = "20px",
  email,
  outfitsNum = 2,
  searchTop = 30,
  storeId = "all",
  buttonText = "Style Assistant",
  suggestedQueries = [
    "Recommend me breathable items for summer",
    "What should I bring for the beach?",
    "I want to impress someone in a date",
    "Suggest some fun looks for a night out with friends",
  ],
  isPreview = false,
  isOnWebsite = false,
  isFullScreen = false,
  hideStoreSelection = false,
}) => {
  const [isOpen, setIsOpen] = useState(() => isPreview || JSON.parse(localStorage.getItem("chatWidgetOpen")) || false);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState(() => JSON.parse(localStorage.getItem("chatWidgetMessages")) || []);
  const [abortController, setAbortController] = useState(null);
  const [isInitialScreen, setIsInitialScreen] = useState(true);
  const messagesEndRef = useRef(null);
  const [conversationHistory, setConversationHistory] = useState("");
  const [botResponses, setBotResponses] = useState([]);
  const shadowRootRef = useRef(null);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [isSignup, setIsSignup] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showSurvey, setShowSurvey] = useState(false);
  const [currentStoreId, setCurrentStoreId] = useState(storeId);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
        setUserEmail(user.email); // Set the user's email automatically
      } else {
        setIsAuthenticated(false);
        setUserEmail("");
      }
    });

    return () => unsubscribe();
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    document.body.style.overflow = !isOpen ? "hidden" : "auto";
  };

  const handleClose = () => {
    setIsInitialScreen(true);
    setShowSurvey(false);
    setShowAuthModal(false);
    if (isPreview) {
      setIsInitialScreen(true);
    } else {
      setIsOpen(false);
      document.body.style.overflow = "auto";
    }
  };


  const clearLocalStorage = () => {
    localStorage.removeItem("chatWidgetOpen");
    localStorage.removeItem("chatWidgetMessages");
    localStorage.removeItem("conversationHistory");
    setMessages([]);
    setConversationHistory("");
    setBotResponses([]);
    setIsInitialScreen(true);
    abortController?.abort();
    toast.success("Chat history cleared");
  };
  useEffect(() => {
    localStorage.setItem("chatWidgetOpen", JSON.stringify(isOpen));
  }, [isOpen]);

  useEffect(() => {
    localStorage.setItem("chatWidgetMessages", JSON.stringify(messages));
  }, [messages]);

  useEffect(() => {
    localStorage.setItem("conversationHistory", conversationHistory);
  }, [conversationHistory]);

  useEffect(() => {
    const savedHistory = localStorage.getItem("conversationHistory");
    if (savedHistory) {
      setConversationHistory(savedHistory);
    }
  }, []);

  const handleSearch = async (e, query = searchQuery) => {
    if (e) e.preventDefault();
    setLoading(true);
    setMessages((prev) => [...prev, { text: query, type: "user" }]);
    setMessages((prev) => [...prev, { text: "Loading", type: "bot", isLoading: true }]);
    setIsInitialScreen(false);
    if (!isPreview) {
      scrollToBottom();
    }

    const controller = new AbortController();
    setAbortController(controller);

    try {
      const user = auth.currentUser;
      const email = user ? user.email : null;
      const phoneNumber = user ? user.phoneNumber : null;

      const response = await fetch(`https://api.styld.ai/api/store/${currentStoreId}/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prompt: query,
          illustrate: false,
          outfits_num: outfitsNum,
          email: email,
          phone: phoneNumber,
          search_top: searchTop,
        }),
        signal: controller.signal,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data && data.items && data.reason) {
        const formattedResponse = {
          reason: data.reason,
          items: data.items,
        };

        setBotResponses((prev) => [...prev, formattedResponse]);

        setMessages((prev) => {
          const filteredMessages = prev.filter(msg => !msg.isLoading);
          const newMessages = [
            ...filteredMessages,
            { text: formattedResponse, type: "bot" },
          ];
          scrollToBottom();
          return newMessages;
        });
      } else {
        throw new Error("Invalid response data");
      }
    } catch (error) {
      if (error?.name === "AbortError") {
        console.log("Fetch aborted");
      } else {
        console.error("Error fetching results:", error);
        toast.error("Failed to fetch results. Please try again later.");
      }
    } finally {
      setLoading(false);
      setAbortController(null);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleAuthSuccess = (user) => {
    setUserEmail(user.email);
    setIsAuthenticated(true);
    setShowAuthModal(false);
    setShowSurvey(true);
  };

  const handleLogout = () => {
    auth.signOut().then(() => {
        setIsAuthenticated(false);
        setUserEmail("");
    }).catch((error) => {
        console.error("Logout error:", error);
        toast.error("Failed to log out. Please try again.");
    });
  };

  const OutfitItem = ({ item }) => (
    <div className="outfit-item-container col-12 col-md-6 col-lg-4">
      <a
        className="outfit-item"
        href={item?.link?.replace("https:/w", "https://w")}
        target="_blank"
        rel="noopener noreferrer"
      >
        <p style={{ fontSize: '12px', position: 'absolute', top: '5px', left: '5px', backgroundColor:'white', zIndex:10000 }}>{item?.type}</p>
        <img src={item?.image} alt={item?.title} />
        <div className="item-details">
          <p className="item-name">{item?.title}</p>
          <p className="item-price">${String(item?.price)?.replace("$","")}</p>
        </div>
      </a>
      <button
        className="add-to-cart-button"
        onClick={(e) => {
          e.preventDefault();
          window.open(item.link, "_blank");
        }}
        title="Add to Cart"
      >
        <Cart />
      </button>
      {item.explanation && (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tooltip-${item.id}`}>{item.explanation}</Tooltip>}
        >
          <div className="explanation-icon">?</div>
        </OverlayTrigger>
      )}
    </div>
  );
  return (
    <div ref={shadowRootRef} className={isPreview || isFullScreen ? 'preview-mode' : ''}>
      {!isPreview && !isOnWebsite && !isFullScreen && (
        <button 
          className="toggle-button" 
          onClick={handleToggle}
          style={{
            backgroundColor: primaryColor,
            color: buttonTextColor,
            fontFamily: fontFamily,
            borderRadius: borderRadius,
          }}
        >
          <Send className="me-2" /> {buttonText}
        </button>
      )}
      {(isOpen || isPreview || isOnWebsite || isFullScreen) && (
        <div 
          className={`drawer-overlay ${isPreview || isOnWebsite || isFullScreen ? 'preview-mode' : ''}`} 
          onClick={isPreview || isOnWebsite || isFullScreen ? null : handleClose}
          style={isPreview || isOnWebsite || isFullScreen ? { position: 'static', backgroundColor: 'transparent' } : {}}
        >
          <div 
            className="drawer" 
            onClick={(e) => e.stopPropagation()} 
            style={{ 
              '--primary-color': primaryColor,
              '--secondary-color': secondaryColor,
              '--background-color': backgroundColor,
              '--text-color': textColor,
              '--button-text-color': buttonTextColor,
              '--font-family': fontFamily,
              '--border-radius': borderRadius,
              ...(isPreview || isOnWebsite || isFullScreen ? { 
                position: 'static', 
                width: '100%', 
                height: isFullScreen ? '100vh' : '600px',
                borderRadius: isFullScreen ? '0' : borderRadius,
              } : {}),
            }}
          >
            {isInitialScreen ? (
              <div className="initial-screen p-5">
                {!isPreview && (
                  <button className="close-button-init" onClick={handleClose}>
                    X
                  </button>
                )}

                {showSurvey ? (
                  <Survey
                    onComplete={() => {
                      setIsInitialScreen(false); // Set to false to show the survey
                      handleClose(); // Call the completion handler
                    }} 
                    isWidget={true} 
                    onClose={() => {
                      setShowSurvey(false);
                      setIsInitialScreen(true);
                    }} 
                  />
                ) : (
                  <>
                    {isAuthenticated ? (
                      <div className="d-flex justify-content-end w-100 gap-2">
                            <button className="quiz-button" onClick={() => setShowSurvey(true)}>
                                Quiz
                            </button>
                            <button className="logout-button" onClick={handleLogout}>
                                Log Out
                            </button>
                      </div>
                    ) : (
                      <button 
                          className="login-button" 
                          onClick={() => setShowAuthModal(true)} 
                      >
                          Sign In
                      </button>
                    )}
                    <a
                      href="https://styld.ai"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={LogoImage}
                        style={{ objectFit: "contain", width: "200px" }}
                        alt="Style Assistant Logo"
                        className="logo"
                      />
                    </a>
                    <h1>Personalized Fashion</h1>
                    <p>Let me know what you're looking for.</p>
                    {isOnWebsite && !hideStoreSelection && (
                      <select
                        value={currentStoreId}
                        onChange={(e) => setCurrentStoreId(e.target.value)}
                        style={{
                          marginBottom: '10px',
                          padding: '5px',
                          borderRadius: '5px',
                          border: '1px solid #ccc',
                        }}
                      >
                        <option value="all">All Stores</option>
                        <option value="mcm">MCM</option>
                        <option value="revolve">Revolve</option>
                        <option value="zara">Zara</option>
                        <option value="handm">H&M</option>
                        <option value="ssense">SSENSE</option>
                        {/* Add more store options as needed */}
                      </select>
                    )}
                    <form onSubmit={handleSearch} className="chat-input chat-input-initial">
                      <input
                        type="text"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Ask me anything"
                      />
                  <button type="submit">
                    {loading ? <div className="loader"></div> : <Send />}
                  </button>
                    </form>
                    <div className="suggested-queries">
                      {suggestedQueries.map((query, index) => (
                        <button
                          key={index}
                          onClick={() => handleSearch(null, query, true)}
                        >
                          {query}
                        </button>
                      ))}
                    </div>
                  </>
                )}
              </div>
            ) : (
              <>
                <div className="chat-header">
                  <h2>Style Assistant</h2>
                  <div className="header-buttons">
                    <button className="start-again" onClick={clearLocalStorage}>
                      Start Again
                    </button>
                    <button className="close-button" onClick={handleClose}>
                      &times;
                    </button>
                  </div>
                </div>
                <div className="messages">
                  {messages.map((msg, index) => (
                    <div key={index} className={`message ${msg.type}`}>
                      {msg.type === "user" ? (
                        <div className="user-message">{msg.text}</div>
                      ) : (
                        <div className="bot-message">
                          {msg.text && typeof msg.text === 'object' ? (
                            <>
                              <p>{msg.text.reason}</p>
                              <div className="row">
                                {msg.text.items.map((item, itemIndex) => (
                                  <OutfitItem key={itemIndex} item={item} />
                                ))}
                              </div>
                            </>
                          ) : msg.isLoading ? (
                            <div className="thinking-loader">
                              <span className="thinking-text">Thinking</span>
                              <span className="dot-loader">
                                <span className="dot"></span>
                                <span className="dot"></span>
                                <span className="dot"></span>
                              </span>
                            </div>
                          ) : (
                            <p>{msg.text}</p>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                  <div ref={messagesEndRef} />
                </div>
                <form className="chat-input" onSubmit={(e) => handleSearch(e)}>
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Ask me anything"
                  />
                  <button type="submit">
                    {loading ? <div className="loader"></div> : <Send />}
                  </button>
                </form>
              </>
            )}
            <InWidgetAuthModal 
              show={showAuthModal} 
              handleClose={() => setShowAuthModal(false)}
              onAuthSuccess={handleAuthSuccess}
              isSignup={isSignup}
              toggleAuthMode={() => setIsSignup(!isSignup)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatWidgetComponent;
