import { analytics } from './firebaseConfig';
import { logEvent, setUserId } from "firebase/analytics";
import { auth } from './firebaseConfig';

const isProduction = process.env.NODE_ENV === 'production';

// New function to set user ID
export const setAnalyticsUserId = (userId) => {
  setUserId(analytics, userId);
};

export const trackEvent = (eventName, eventParams = {}) => {
  if (!isProduction) {
    console.log('Analytics event (dev):', eventName, eventParams);
    return;
  }

  if (!analytics) {
    console.warn('Analytics object is not initialized');
    return;
  }

  const user = auth.currentUser;
  const userId = user ? user.uid : 'anonymous';

  // Set user ID before logging the event
  setAnalyticsUserId(userId);

  logEvent(analytics, eventName, {
    ...eventParams,
    // No need to include user_id here as it's set globally
  });
};

export const trackSearch = (searchTerm, storeId) => {
  trackEvent('search', { search_term: searchTerm, store_id: storeId });
};

export const trackPageView = (pageName) => {
  trackEvent('page_view', { page_name: pageName });
};

export const trackUserEngagement = (engagementType) => {
  trackEvent('user_engagement', { engagement_type: engagementType });
};

export const trackProductClick = (productId, productName, searchQuery, storeId) => {
  trackEvent('product_click', { 
    product_id: productId, 
    product_name: productName, 
    search_query: searchQuery,
    store_id: storeId
  });
};
