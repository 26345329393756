import React, { useState, useEffect } from "react";
import "./demoB2C.css";
import userFullBody from "../../assets/images/demo/user-full-body.png";
import prodThumbnail from "../../assets/images/demo/productThumbnail.png";
import { auth } from '../../firebaseConfig';
import Spinner from 'react-bootstrap/Spinner';
import { toast } from 'react-toastify'; // Add this import
import { trackSearch, trackProductClick } from '../../analytics';
import Alert from 'react-bootstrap/Alert'; // Add this import
import { OverlayTrigger, Tooltip } from 'react-bootstrap'; // Add this import

const DemoB2C = ({ apiEndpoint, primaryColor, secondaryColor, initialStore }) => {
  const [likedProducts, setLikedProducts] = useState({});
  const [sharedProducts, setSharedProducts] = useState({});
  const [viewedProducts, setViewedProducts] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [fetchedProducts, setFetchedProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [storeId, setStoreId] = useState("all");
  const [searchSuggestions] = useState([
    "I'm going for vacation in Italy, what can I wear?",
    "Brunch outfits",
    "What can I wear to a dinner date?"
  ]);
  const [currentSuggestionIndex, setCurrentSuggestionIndex] = useState(0);
  const [hasSearched, setHasSearched] = useState(false);
  const [availableStores] = useState(["all", "revolve", "zara", "h&m", "ssense", "mcm", "wild_fabrik"]);
  const [searchTop, setSearchTop] = useState(20);
  const [searchReason, setSearchReason] = useState("");
  const [selectedStore, setSelectedStore] = useState(initialStore || "all");

  useEffect(() => {
    const savedSearchQuery = localStorage.getItem('searchQuery');
    const savedFetchedProducts = localStorage.getItem('fetchedProducts');
    if (savedSearchQuery) {
      setSearchQuery(savedSearchQuery);
    }
    if (savedFetchedProducts && savedFetchedProducts !== 'undefined' && fetchedProducts.length === 0) {
      const parsedProducts = JSON.parse(savedFetchedProducts);
      setFetchedProducts(parsedProducts);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('likedProducts', JSON.stringify(likedProducts));
  }, [likedProducts]);

  useEffect(() => {
    localStorage.setItem('sharedProducts', JSON.stringify(sharedProducts));
  }, [sharedProducts]);

  useEffect(() => {
    localStorage.setItem('viewedProducts', JSON.stringify(viewedProducts));
  }, [viewedProducts]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSuggestionIndex((prevIndex) =>
        (prevIndex + 1) % searchSuggestions.length
      );
    }, 3000); // Change suggestion every 3 seconds

    return () => clearInterval(interval);
  }, [searchSuggestions]);

  const toggleLike = (productId) => {
    setLikedProducts((prev) => ({ ...prev, [productId]: !prev[productId] }));
  };

  const toggleShare = (productId) => {
    setSharedProducts((prev) => ({ ...prev, [productId]: !prev[productId] }));
  };

  const toggleView = (productId) => {
    setViewedProducts((prev) => ({ ...prev, [productId]: !prev[productId] }));
  };

  const handleSearch = async (e, query = searchQuery, newSearchTop = 20, isLoadMore = false) => {
    if (e) e.preventDefault();
    setSearchQuery(query);
    setLoading(true);
    if (!isLoadMore) {
      setFetchedProducts([]);
    }
    setHasSearched(true);
    setSearchTop(newSearchTop);
    console.log(query);
    const user = auth.currentUser;
    const email = user ? user.email : null;
    const phoneNumber = user ? user.phoneNumber : null;

    const maxRetries = 10;
    let retries = 0;
    let success = false;

    while (retries < maxRetries && !success) {
      try {
        const response = await fetch(apiEndpoint || 'https://api.styld.ai/demo', {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            prompt: query,
            email: email || null,
            phone: phoneNumber || null,
            search_top: newSearchTop,
            store_id: selectedStore.replace("h&m","handm"),
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data && data.items) {
          if (isLoadMore) {
            setFetchedProducts(prevProducts => [...prevProducts, ...data.items.slice(prevProducts.length)]);
          } else {
            setFetchedProducts(data.items);
          }
          
          // Set the search reason
          setSearchReason(data.reason || "");

          localStorage.setItem('searchQuery', query);
          localStorage.setItem('fetchedProducts', JSON.stringify(isLoadMore ? [...fetchedProducts, ...data.items.slice(fetchedProducts.length)] : data.items));
          success = true;
        } else {
          throw new Error('Invalid response data');
        }
      } catch (error) {
        console.error(`Attempt ${retries + 1} failed:`, error);
        retries++;
        if (retries === maxRetries) {
          toast.error('Failed to fetch results. Please try again later.');
        }
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    }

    setLoading(false);
    if (!isLoadMore) {
      trackSearch(query, selectedStore);
    }
  };

  const handleLoadMore = () => {
    const newSearchTop = Math.min(searchTop + 20, 1000);
    handleSearch(null, searchQuery, newSearchTop, true);
  };

  const handleProductClick = (productId, productName) => {
    trackProductClick(productId, productName, searchQuery, selectedStore);
  };

  return (
    <div className="demo-b2c-container" style={{ '--primary-color': primaryColor, '--secondary-color': secondaryColor }}>
      <div className="demo-b2c-content">

        <div className="search-container">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch(e, searchQuery); // Trigger search on Enter key
              }
            }}
            placeholder="Enter your search query"
            className="search-input"
          />
          <select
            value={selectedStore}
            onChange={(e) => setSelectedStore(e.target.value)}
            className="store-select"
          >
            {availableStores.map((store) => (
              <option key={store} value={store}>
                {store.toUpperCase()}
              </option>
            ))}
          </select>
          <button
            type="button" // Change button type to "button"
            className="search-button"
            onClick={(e) => handleSearch(e, searchQuery)}
            disabled={loading}
          >
            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <>
                <span>Search</span>
                <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M10.2061 0.664063C8.65141 0.664188 7.11926 1.01714 5.73752 1.69347C4.35577 2.3698 3.16449 3.34989 2.26308 4.55198C1.36166 5.75407 0.77624 7.1433 0.555661 8.60376C0.335081 10.0642 0.485738 11.5536 0.995061 12.9475C1.50438 14.3415 2.35761 15.5997 3.48354 16.6171C4.60947 17.6345 5.97547 18.3816 7.46756 18.7962C8.95966 19.2107 10.5346 19.2806 12.0609 19.0001C13.5873 18.7195 15.0208 18.0966 16.2419 17.1834L20.4309 21.1587C20.6473 21.357 20.937 21.4667 21.2378 21.4642C21.5385 21.4617 21.8262 21.3472 22.0389 21.1454C22.2516 20.9436 22.3722 20.6706 22.3748 20.3852C22.3774 20.0998 22.2618 19.8248 22.0529 19.6195L17.8638 15.6443C18.9971 14.2799 19.7027 12.6404 19.9 10.9133C20.0972 9.18613 19.778 7.4412 18.979 5.87817C18.18 4.31513 16.9334 2.99714 15.382 2.07503C13.8305 1.15292 12.0368 0.663946 10.2061 0.664063ZM2.7503 9.91647C2.7503 8.03996 3.53583 6.24032 4.93406 4.91343C6.3323 3.58654 8.22872 2.8411 10.2061 2.8411C12.1835 2.8411 14.08 3.58654 15.4782 4.9195C16.8764 6.24032 17.662 8.03996 17.662 9.91647C17.662 11.793 16.8764 13.5926 15.4782 14.9195C14.08 16.2464 12.1835 16.9918 10.2061 16.9918C8.22872 16.9918 6.3323 16.2464 4.93406 14.9195C3.53583 13.5926 2.7503 11.793 2.7503 9.91647Z" fill="#F1EFE7"/>
                </svg>
              </>
            )}
          </button>
        </div>
        <div className="search-suggestions">
          {searchSuggestions.map((suggestion, index) => (
            <button
              key={index}
              className="suggestion-button"
              onClick={(e) => {
                handleSearch(e, suggestion);
              }}
            >
              {suggestion}
            </button>
          ))}
        </div>
        {searchReason && (
          <Alert variant="secondary" className="mt-3">
            {searchReason}
          </Alert>
        )}
        <div className="product-grid">
          {fetchedProducts.map((product, index) => (
            <div
              key={index}
              className="product-item"
              onClick={() => {
                handleProductClick(product.id, product.name);
                window.open(product?.link?.replace("https:/w", "https://w"), "_blank");
              }}
            >
              <a href={product?.link?.replace("https:/w", "https://w")} onClick={(e) => e.preventDefault()}>
                <img
                  src={product?.image}
                  alt={product?.name}
                  className="product-image"
                />
              </a>
              {product.explanation && (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id={`tooltip-${index}`}>{product.explanation}</Tooltip>}
                >
                  <div className="explanation-icon">?</div>
                </OverlayTrigger>
              )}
            </div>
          ))}
        </div>
        
        {/* Add the Load More button */}
        {fetchedProducts.length > 0 && (
          <div className="load-more-container">
            <button
              className="load-more-button btn btn-dark"
              onClick={handleLoadMore}
              disabled={loading}
            >
              {loading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Load More"
              )}
            </button>
          </div>
        )}
        

      </div>
    </div>
  );
};

export default DemoB2C;
