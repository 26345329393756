import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { auth } from "../firebaseConfig";
import {
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { trackUserEngagement } from '../analytics';

function AuthModal({ show, handleClose, onAuthSuccess }) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [isCodeSent, setIsCodeSent] = useState(false);

  const setupRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        'size': 'invisible',
        'callback': () => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        }
      });
    }
  };

  const handleSendCode = async () => {
    if (!phoneNumber || !isValidPhoneNumber(phoneNumber)) {
      alert("Please enter a valid phone number");
      return;
    }

    try {
      setupRecaptcha();
      // Use the phoneNumber directly, as it now includes the country code
      const confirmation = await signInWithPhoneNumber(auth, phoneNumber, window.recaptchaVerifier);
      setConfirmationResult(confirmation);
      setIsCodeSent(true);
      trackUserEngagement('phone_code_sent');
    } catch (error) {
      alert("Error sending verification code: " + error.message);
    }
  };

  const handleVerifyCode = async () => {
    if (!verificationCode) {
      alert("Please enter the verification code");
      return;
    }

    try {
      const result = await confirmationResult.confirm(verificationCode);
      trackUserEngagement('phone_login_success');
      
      // Check if onAuthSuccess is a function before calling it
      if (typeof onAuthSuccess === 'function') {
        onAuthSuccess(result.user);
      } else {
        console.log('Authentication successful', result.user);
        // You can add additional logic here if needed
      }
      
      handleClose();
    } catch (error) {
      alert("Error verifying code: " + error.message);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} dialogClassName="custom-modal">
      <Modal.Header
        closeButton
        closeVariant="white"
        style={{
          background: "black",
          color: "#F1EFE7",
          fontFamily: "Inter, sans-serif",
        }}
      >
        <Modal.Title>Phone Authentication</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ backgroundColor: "#F1EFE7", fontFamily: "Inter, sans-serif" }}
      >
        <Form>
          <Form.Group controlId="formPhoneNumber">
            <Form.Label>Phone Number:</Form.Label>
            <PhoneInput
              placeholder="Enter phone number"
              value={phoneNumber}
              onChange={setPhoneNumber}
              defaultCountry="US"
              className="PhoneInput"
              inputClass="PhoneInputInput"
              international
              countryCallingCodeEditable={false}
            />
          </Form.Group>

          {isCodeSent && (
            <Form.Group controlId="formVerificationCode">
              <Form.Label>Verification Code:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter verification code"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                style={{
                  backgroundColor: "transparent",
                  border: "2px solid #CECCC5",
                  borderRadius: "25px",
                  fontFamily: "Inter, sans-serif",
                }}
                className="custom-input"
              />
            </Form.Group>
          )}
        </Form>
        <Button
          variant="primary"
          className="w-100 mt-2 custom-button"
          onClick={isCodeSent ? handleVerifyCode : handleSendCode}
          style={{
            backgroundColor: "#FE3C00",
            borderRadius: "25px",
            fontWeight: "bold",
            fontFamily: "Inter, sans-serif",
            border: "none",
          }}
        >
          {isCodeSent ? "Verify Code" : "Send Verification Code"}
        </Button>
      </Modal.Body>
      <div id="recaptcha-container"></div>
      <style>
        {`
          .custom-input:hover, .custom-input:focus {
            border-color: #FE3C00 !important;
            // box-shadow: 0 0 0 0.2rem rgba(254, 60, 0, 0.25) !important;
          }
            .custom-button-google:hover{
            background-color: #CECCC5 !important;
            }
            .custom-button:hover{
            background-color: #FF6637 !important;
            }
          .custom-button:focus {
            background-color: #FE3C00 !important;
            border-color: #FE3C00 !important;
            color: white !important;
          }
          .custom-link:hover, .custom-link:focus {
            color: #FE3C00 !important;
            text-decoration: underline !important;
          }
             .modal-content {
      border-radius: 25px !important;
      overflow: hidden;
    }

    .custom-modal {
      max-width: 500px;
      margin: 1.75rem auto;
    }

          /* Custom styles for phone input */
          .PhoneInput {
            background-color: transparent;
            border: 2px solid #CECCC5;
            border-radius: 25px;
            padding: 0.375rem 0.75rem;
            font-family: Inter, sans-serif;
          }

          .PhoneInputInput {
            background-color: transparent;
            border: none;
            font-family: Inter, sans-serif;
            color: #000;
          }

          .PhoneInputCountrySelect {
            background-color: transparent;
            border: none;
            font-family: Inter, sans-serif;
          }

          .PhoneInputCountryIcon {
            border: none;
            box-shadow: none;
          }

          .PhoneInput--focus {
            border-color: #FE3C00;
          }

          .PhoneInputInput:focus {
            outline: none;
          }
        `}
      </style>
    </Modal>
  );
}

export default AuthModal;